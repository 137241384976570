<template>
  <div>
    <v-card v-if="selectedEvent.idTarea" min-width="500px" max-width="500px" flat>
      <v-toolbar :color="selectedEvent.color">
        <v-toolbar-title style="font-weight: bold; color: #000;">{{ tipo.find(t => t.val == selectedEvent.tipo).title }}</v-toolbar-title>
      </v-toolbar>
      <v-row class="mb-2">
        <v-col class="pb-0" style="border-bottom: 1px solid var(--v-secondary-lighten5);" cols="12"><b>ESPACIO:</b> {{ selectedEvent.espacio.join(" + ") }}</v-col>
        <v-col class="py-0" style="border-bottom: 1px solid var(--v-secondary-lighten5);" cols="12"><b>HORARIO:</b> {{ horario.filter(h => selectedEvent.horario.includes(h.val)).map(x => x.title).join(" + ") }}</v-col>
        <v-col class="py-0" style="border-bottom: 1px solid var(--v-secondary-lighten5);" cols="12"><b>MEDIOS:</b> {{ medio.filter(h => selectedEvent.medio.includes(h.val)).map(x => x.title).join(" + ") }}</v-col>
        <v-col class="py-0" style="border-bottom: 1px solid var(--v-secondary-lighten5);" cols="12" v-if="selectedEvent.tipo == 5"><b>MEDIOS ALQUILER:</b> {{ medio.filter(h => selectedEvent.medio.includes(h.val)).map(x => x.title).join(" + ") }}</v-col>
        <v-col class="py-0" style="border-bottom: 1px solid var(--v-secondary-lighten5);" cols="12" v-html="'<b>EXPLICACIÓN:</b> ' + selectedEvent.explicacion.replace(/\n/g, '<br>')"></v-col>
        <v-col class="py-0" style="border-bottom: 1px solid var(--v-secondary-lighten5);" cols="12" v-if="selectedEvent.tipo != 5"><b>EQUIPO SALA:</b> {{ equipoMai.filter(h => selectedEvent.equipoMai.includes(h.idUnidadEquipo)).map(x => x.nombre + ' ' + x.apellidos).join(" + ") }}</v-col>
        <v-col class="py-0" style="border-bottom: 1px solid var(--v-secondary-lighten5);" cols="12" v-if="selectedEvent.tipo != 4"><b>EQUIPO BODEGUEROS:</b> {{ equipoBdg.filter(h => selectedEvent.equipoBdg.includes(h.idUnidadEquipo)).map(x => x.nombre + ' ' + x.apellidos).join(" + ") }}</v-col>
      </v-row>
      <v-spacer></v-spacer>
      <v-card-actions>
        <v-btn
          color="primary"
          :elevation="0"
          rounded
          @click.stop="edit"
          class="mr-1"
        >
          <v-icon left>mdi-pencil</v-icon>
          editar
        </v-btn>
        <v-btn
          color="info"
          :elevation="0"
          rounded
          @click.stop="duplicar"
          class="mr-1"
        >
          <v-icon left>mdi-content-copy</v-icon>
          duplicar
        </v-btn>
        <v-btn
          color="error"
          :elevation="0"
          rounded
          @click.stop="deleteEvent(selectedEvent.idTarea)"
        >
          <v-icon left>mdi-delete</v-icon>
          eliminar
        </v-btn>
      </v-card-actions>
    </v-card>
    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
export default {
  props: {
    focus: Number,
    tipo: Array,
    equipoMai: Array,
    equipoBdg: Array,
    horario: Array,
    medio: Array,
    selectedEvent: Object,
  },
  data() {
    return {
    };
  },
  components: {
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  methods: {
    async deleteEvent(idTarea) {
      const confirmation = await this.$refs.confirm.open(
        "Eliminar la tarea",
        "¿Estás seguro? <br><br> Esta acción no se puede deshacer",
        { color: "error" }
      );
      if (!confirmation) return;

      try {
        const { data } = await axios({
          url: `/tareas/${idTarea}`,
          method: "DELETE",
        });
        this.$root.$emit("snack", "La tarea se ha eliminado correctamente");
        this.$emit("reload");
      } catch (error) {
        this.$root.$emit("snack", "No se ha podido eliminar la tarea");
        console.error(error);
      }
    },
    edit(){
      this.$router.push({path: `/tareas/tarea/${this.selectedEvent.idTarea}`, query: { focus: this.focus }})
    },
    async duplicar() {
      const confirmation = await this.$refs.confirm.open(
        "Duplicar la tarea",
        "¿Estás seguro?",
        { color: "error" }
      );
      if (!confirmation) return;

      try {
        const { data } = await axios({
          url: `/tareas/duplicar/${this.selectedEvent.idTarea}`,
          method: "POST",
        });
        this.$root.$emit("snack", "La tarea se ha duplicado correctamente");
        this.$emit("reload");
      } catch (error) {
        this.$root.$emit("snack", "No se ha podido eliminar la tarea");
        console.error(error);
      }
    },
  },
};
</script>

<style>
</style>